.nav-menu-two {
    display: flex;
    gap: 2rem;
    align-items: center;

    list-style: none;
    margin-right: 40px;
    min-height: 50px;
    margin-top:15px;
    font-size: 14px;
}

.menu-button-2{
    margin-right:20px;
    font-size:30px;
}
.navbar-wrapper-2 {
    display: flex;
    justify-content: space-between ;
    align-items: center;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    padding: 7px 10px;
    height:85px;
}

.navlist {
    font-family: 'Poppins', sans-serif;
    font-weight: 400; /* Regular weight */
    color: #555; /* Soft gray for easy reading */
    line-height: 1.6; /* Improved line height for readability */
    text-align: center; /* Justified text for a cleaner look on larger screens */
    max-width: 80%; /* Ensures paragraphs aren't too wide on large screens */
    margin-left: auto; /* Centering the paragraph when combined with margin-right */
    margin-right: auto; /* Centering the paragraph */


}

.navbar-logo-left-container {
    background-color: #f2f2f2;
}

.nav-link {
    padding: 7px 10px;
    border-radius: 15px;
    text-decoration: none; /* Remove underline */
    transition: all 0.3s ease;
    font-weight: bold;
    margin-right: 20px;
}

    .nav-link:hover {
        background-color: #198754;
        color: #f2f2f2;
        padding: 20px;
    }

.button-primary-2 {
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #198754;
    color: #f2f2f2;
    text-decoration: none;
    transition: all 0.3s ease;
}

    .button-primary-2:hover {
        background-color: #146c43;
        color: #f2f2f2;

    }

.navbar-brand {
    margin-right: 20px;
    margin-left:20px;
    margin-top:10px;
    margin-bottom:10px;
}
.nav-link:visited {
    color: #000; /* Assuming the default color is black */
}

.button-primary-2:visited {
    color: #f2f2f2; /* As per your "Get Started" button */
}

body, h1, h2, h3, h4, h5, h6, p, li, div {
    font-family: 'Poppins', sans-serif;

}



/* For larger screens */
@media (min-width: 992px) {
    .menu-button-2 {
        display: none;
    }
}

/* For smaller screens */
@media (max-width: 991px) {
    .nav-menu-wrapper {
        display: flex;
        justify-content:center;
    }

        .nav-menu-wrapper.show {
            display: block;
        }

    .nav-menu-two {
        flex-direction: column;
        gap: 0.5rem; /* Adjust as needed */
        margin-right: 20px; /* Reset margin-right */
    }
    .nav-link:hover {
        background-color: #198754;
        color: #f2f2f2;
    }
    .nav-menu-wrapper > .nav-menu-two {
        margin-top:30px;
    }

    .nav-link {
        margin-top: 10px;
    }

}