body, html {
  height: calc(100vh); /* Subtracting 15px from the full viewport height */
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}
.Paragraph{
    
}
.Container {
    height: auto;
    min-height: 100vh;
}
.info-card {
    background-color: #188754 !important;
    color: white !important;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100% !important; /* Ensures equal height for all cards */
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.5) !important; /* Subtle shadow */
}


.card-text{
    color:#f4f4f4
}

.info-icon {
  width: 150px; /* Adjust size as needed */
  height: auto;
  margin-bottom: 10px;
}

.founder-image {
  width: 100%;
  max-width: 300px; /* Adjust size as needed */
  display: block;
  margin: 0 auto;
}

.row {
  margin-bottom: 20px; /* Add space between rows */
}

/* Styling for the founder section text */
.founder-section {
  text-align: justify; /* Justifying the text */
}

.founder-section h3 {
  color: #188754; /* Green color for the founder title */
  font-weight: bold; /* Making the text bold */
}

@media (max-width: 768px) {
    .info-card {
        background-color: #188754;
        color: white;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%; /* Ensures equal height for all cards */
        margin-bottom:20px;
    }

    }
