.benefit-card {
    position: relative;
    border: 1px solid grey !important;
    background-color: #188754;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100% !important; /* Ensures equal height for all cards */
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.5) !important; /* Subtle shadow */
}

    .benefit-card .card-header {
        position: absolute;
        top: -50px; /* Offset from the top of the card */
        left: 50%; /* Centering horizontally */
        transform: translateX(-50%); /* Adjusting centering */
        background-color: #188754;
        border: 1px solid grey;
        padding: 10px;
        width: 90%; /* Width relative to the card */
        display: flex;
        align-items: center;
        justify-content: center; /* Added to center the content */
        gap: 10px;
        color: white; /* Text color for the header */
    }

        .benefit-card .card-header .icon {
            width: 100px; /* Adjust based on actual icon size */
            height: auto;
        }

        .benefit-card .card-header h2 {
            font-size: 1.5rem; /* Adjusted font size */
            margin: 0;
            text-align: center; /* Ensuring text is centered */
        }

    .benefit-card .card-body {
        padding-top: 60px; /* Push content down to not overlap with header */
    }

        .benefit-card .card-body .card-text {
            color: Black; /* Set the text color for the body of the card */
        }

@media (max-width: 768px) {
    .benefit-card {
        position: relative;
        border: 1px solid grey !important;
        background-color: #188754;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100% !important; /* Ensures equal height for all cards */
    }
}
