.ovsection {
}

.hero-wrapper-two {
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    text-align: center;
    background-color: #f7f9fc;
    background: linear-gradient(120deg, #198754, #f7f9fc);
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
    margin: 2rem 0;
    overflow: visible;
    height: auto;
}

.heading-19, .heading {
    color: #333;
    margin-bottom: 1rem;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.heading-19 {
    font-size: 2rem;
    font-weight: bold;
}

.heading {
    font-size: 1.5rem;
}

.list-2 {
    list-style-type: none;
    text-align: center;
    width: 100%;
    margin: 2rem auto;
    padding-left: 1rem;
}

    .list-2 li {
        margin-bottom: 1rem;
        font-size: 1.1rem;

    }
.li logo {
    width: 2px;
}
.button-primary-2 {
    padding: 10px 20px;
    background-color: #198754;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s ease;
    margin-top:800px;
}

    .button-primary-2:hover {
        background-color: #146c43;
    }

@media (max-width: 768px) {
    .hero-wrapper-two {
        align-items: flex-start;
    }
    .heading-19 {
        font-size: 1.6rem;
        font-weight: bold;
    }

    .heading {
        font-size: 1.4rem;
    }


        .list-2 li {
            margin-bottom: 0.5rem;
            font-size: 1.2rem;
        }
}
