/* General styles */
.section {
    height: auto;
    min-height: calc(100vh - 50px);
    margin-bottom: 50px;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers children horizontally */
    justify-content: center; /* Centers children vertically */
    padding: 1rem;
    text-align: center;
    margin-bottom:50px;
}

.centered-heading {
    font-size: 2.5rem; /* Adjust as needed */
    font-weight: bold;
}



.herop {
    margin-bottom: 24px;
    margin-top: 24px;
    text-align: justify;
    justify-content: left;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: 400; /* Regular weight */
    color: #555; /* Soft gray for easy reading */
    line-height: 1.6; /* Improved line height for readability */
    max-width: 80%; /* Ensures paragraphs aren't too wide on large screens */
}
.row{
    margin-top:5000px;
}

.list-2 {
}

.heroh1 {
    font-family: 'Poppins', sans-serif;
    font-size: 3.2rem;
    font-weight: 700;
    color: #2a2a2a;
    text-align: center;
    letter-spacing: 2px;
    margin-bottom: 2rem;
    width: 100vw;
    position: relative;
    overflow: hidden; /* For the animated background */
    padding: 10px 0; /* Adding some padding for the background highlight */
    /* Adding a subtle shadow for depth */
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
    /* Adding a hover effect */
    transition: transform 0.3s;
}
.heroh2 {
    font-family: 'Poppins', sans-serif;
    color: #555; /* Soft gray for easy reading */
    text-align: center;
    letter-spacing: 2px;
    margin-top: 10%;
    margin-bottom: 10%;
}
.herolist {
    color: #555; /* Soft gray for easy reading */
    list-style-type: none;
    text-align: left;
    width: 100%;
    margin: 2rem auto;
    padding-left: 1rem;
    line-height: 1.6; /* Improved line height for readability */
    font-size: 18px;
    font-weight: 400; /* Regular weight */
}
    .heroh1:hover {
        transform: scale(1.05); /* Slightly enlarging the text on hover */
    }

    /* Animated background */
    .heroh1::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 200%;
        height: 100%;
        background: linear-gradient(120deg, #f0f0f0, #198754, #f0f0f0, #198754);
        z-index: -1;
        animation: movingBackground 5s forwards;
        animation-iteration-count: 1;
    }
@keyframes movingBackground {
    0%, 100% {
        left: 0;
    }

    50% {
        left: 100%;
    }
}

    .animation-complete::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0; /* Set to the final state of the animation */
        width: 100%;
        height: 100%;
        background: #198754; /* The final color of the animated background */
        z-index: -1;
        /* Remove the animation properties so they don't interfere */
    }







.button-primary {
    padding: 10px 20px;
    background-color: #198754; /* Adjust color as needed */
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none; /* Removes underline */
    transition: background-color 0.3s ease;
}

    .button-primary:hover {
        background-color: #146c43; /* Darker shade for hover effect */
    }

.w-layout-blockcontainer {
    display: flex;
    justify-content: center;
}

.image-7 {
    max-width: calc(60vh ); /* Ensures image doesn't overflow its container */
    margin-bottom:50px;
}







/* For mobile and smaller screens, you may want to adjust styles */
@media (max-width: 768px) {

    .section {
        height: auto;
        margin-bottom: 50px;
    }


    .heroh1 {
        font-size: 1.6rem;
        font-weight: 700;
    }

    .herop {
        font-size: 1.2rem;
        margin-bottom:24px;
    }

    .image-7 {
        width: 60%; /* Adjust as needed */

    }
}
