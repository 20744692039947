.hero-wrapper-FL {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60vh; /* Set a fixed height for the carousel */
    overflow: hidden;
    position: relative;
    background-color: #f7f9fc;
    background: linear-gradient(120deg,#f7f9fc, #198754, #f7f9fc, #198754, #f7f9fc);
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
}

.carousel-slide-FL {
    display: flex;
    align-items: center;
    padding:50px;
}

.carousel-image-FL {
    height: 80%;
    width: auto;
    max-width: 45%;
    transition: opacity 0.3s;
    padding: 20px ;
    margin-left:3%;
    box-shadow: 0px 20px 30px rgb(128, 128, 128); /* Enhanced shadow */
}

@media (max-width: 767px) {
    .hero-wrapper-FL {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 55vh; /* Set a fixed height for the carousel */
        overflow: hidden;
        position: relative;
        background-color: #f7f9fc;
        border-radius: 8px;
        box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
    }

    .carousel-slide-FL {
        display: flex;
        align-items: center;
        padding: 20px;
    }

    .carousel-image-FL {
        max-width: 95%; /* One image takes the full width */
        padding: 0px;
        margin-left: 2.5%;
    }
}