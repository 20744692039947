/* Main container styling */
.gitdiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    background-color: #f7f9fc; /* Soft background color */
}

    .gitdiv > h1 {
        font-family: 'Poppins', sans-serif;
        font-weight: 700; /* Bold and authoritative */
        color: #2a2a2a; /* Deep grey for a professional look */
        text-align: center;
        letter-spacing: 1.5px;
        text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.05); /* Very subtle shadow */
        margin-bottom: 2rem;
        width: 100%;
    }

.heading-13 {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 2rem;
    text-align: center;
}

.form-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    max-width: 650px; /* Limit form width for better presentation */
    background-color: #198754; /* Green color */
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0px 20px 30px rgb(128, 128, 128); /* Enhanced shadow */
}

.field-label-4,
.field-label-7,
.field-label-5,
.field-label-6 {
    font-size: 1.2rem;
    color: #ffffff; /* White color for contrast against green background */
    margin-top: 1rem;
    align-self: flex-start; /* Align labels to the left */
    padding: 0rem;
}

.text-field4,
.text-field-7,
.text-field-2,
.text-field-3 {
    width: 90%; /* Full width of the container */
    padding: 0.8rem;
    border-radius: 5px;
    border: 1px solid #ffffff; /* Border color */
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color: #ffffff; /* White background */
    color: #333; /* Text color */
}

.submit-button-2 {
    font-size: 1.2rem;
    background-color: #ffffff; /* White background */
    color: #198754; /* Green text for contrast */
    padding: 0.8rem 2rem;
    border: none;
    border-radius: 5px;
    margin-top: 1rem;
    cursor: pointer; /* Hand cursor for clickable elements */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
    margin-left: auto;
    margin-right: auto;
    display: block;
}

    .submit-button-2:hover {
        background-color: #d4d4d4; /* Slight color change on hover */
    }

.w-form-done,
.w-form-fail {
    display: none; /* Initially hidden */
    font-size: 1.2rem;
    color: #ffffff;
    margin-top: 1rem;
}

/* If you want to display them, you can use JavaScript to toggle their visibility. */
/* Error Modal */
.error-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Ensure modal is on top */
}


.thankyou-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Above other content */
    background-color: rgba(0, 0, 0, 0.5); /* Dim the background */
}


.modal-content {
    background-color: #198754; /* Green color */
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0px 20px 30px rgb(128, 128, 128); /* Enhanced shadow */
    max-width: 400px;
    text-align: center;
}

    .modal-content h2 {
        font-family: 'Poppins', sans-serif;
        font-size: 2.5rem;
        font-weight: 700;
        color: #ffffff;
        margin-bottom: 1rem;
    }

    .modal-content p {
        font-size: 1.2rem;
        color: #ffffff;
        margin-bottom: 2rem;
    }

    .modal-content button {
        background-color: #ffffff;
        color: #198754;
        padding: 0.8rem 2rem;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;
    }

        .modal-content button:hover {
            background-color: #d4d4d4;
        }
/* Add this to your existing .gitdiv styles */
.is-blurred {
    filter: blur(5px);
}

/* Add responsiveness */
@media (max-width: 768px) {
    .gitdiv > h1 {
        font-size: 1.6REM;
    }

    .field-label-4, .field-label-7, .field-label-5, .field-label-6 {
        font-size: 1.2rem;
        padding: 2px;
        margin-top: 0.2rem;
    }

    .form-block {
        width: 100%
    }

    .text-field4,
    .text-field-7,
    .text-field-2,
    .text-field-3 {
        width: 100%; /* Full width of the container */
        padding: 0.4rem;
        border-radius: 5px;
        border: 1px solid #ffffff; /* Border color */
        margin-top: 0.5rem;
        margin-bottom: 1rem;
        background-color: #ffffff; /* White background */
        color: #333; /* Text color */
    }

    .submit-button-2 {
        font-size: 1rem;
        background-color: #ffffff; /* White background */
        color: #198754; /* Green text for contrast */
        padding: 0.8rem 2rem;
        border: none;
        border-radius: 5px;
        margin-top: 1rem;
        cursor: pointer; /* Hand cursor for clickable elements */
        transition: background-color 0.3s; /* Smooth transition for hover effect */
        margin-left: auto;
        margin-right: auto;
        display: block;
        width: 60%;
    }
}
