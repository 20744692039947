.privacy-policy-container {
    font-family: Arial, sans-serif; /* Update this to match your site's font */
    color: #333; /* Adjust the color to match your site's theme */
    margin: 20px;
    padding: 20px;
    background-color: #f5f5f5; /* Background color */
    border-radius: 10px;
    width: 60%;
    margin-left:20%;
}

.privacy-button {
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #198754;
    color: #f2f2f2;
    text-decoration: none;
    width:80px;
    font:bold;
    
}

.text {
    line-height: 1.6; /* Line spacing */
    text-align: justify; /* Justify text */
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 20px;
    font-size:14px;
}

.h2text {
    text-align: justify; /* Justify text */
    margin-left: 10%;
    margin-bottom: 20px;
    font-size: 18px;
}
strong {
    font:bolder;
}


.last-updated {
    font-style: italic;
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .privacy-policy-container {
        font-family: Arial, sans-serif; /* Update this to match your site's font */
        color: #333; /* Adjust the color to match your site's theme */
        background-color: #f5f5f5; /* Background color */
        border-radius: 10px;
        width: 95%;
        margin-left: 2.5%;
    }

    .text {
        line-height: 1.6; /* Line spacing */
        text-align: left; /* Justify text */
        margin-left: 10%;
        margin-right: 10%;
        margin-bottom: 20px;
    }

    .h2text {
        text-align: left; /* Justify text */
        margin-left: 10%;
        margin-bottom: 20px;
    }
}


   