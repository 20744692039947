body, html {
    height: calc(100vh); /* Subtracting 15px from the full viewport height */
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
}

.FeatContainer {
    height:auto;
}
.features-icon {
    width: 150px; /* Adjust size as needed */
    height: auto;
    margin-bottom: 10px;
}

.heading {
    color: #ffffff;
    text-align: center;
}

.paragraph{
    color: #ffffff;
    text-align: center;
}
.features-card {
    background-color: #188754 !important;
    color: white !important;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100% !important; /* Ensures equal height for all cards */
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.5) !important; /* Subtle shadow */
}
.features> h1 {
    font-family: 'Poppins', sans-serif;
    font-size: 3.2rem;
    font-weight: 700; /* Bold and authoritative */
    color: #2a2a2a; /* Deep grey for a professional look */
    text-align: center;
    letter-spacing: 1.5px;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.05); /* Very subtle shadow */
    width: 100%;
}


/* Columns styling for features */


/* Mobile view: 1 card in a row */
@media (max-width: 768px) {
    .columns-2, .columns, .columns5 {
        flex-direction: column;
    }
    .features > h1 {
        font-size: 1.63rem;
    }
    .Features-image{
        width:30%;
    }
   
}
