/* Main container styling */
.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #198754; /* Dark green background for a distinct footer look */
    border-top: 1px solid #e0e0e0; /* Subtle border at the top of the footer */
    box-shadow: 0px -3px 15px rgba(0, 0, 0, 0.1); /* Subtle shadow at the top */
    position: relative;
    height: 50px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.text-block {
    font-size: 0.8rem;
    color: #ffffff; /* White text for contrast against the dark background */
    text-align: center;
    margin-top:10px;
    margin-bottom:10px;
}

.button-2 {
    font-size: 1rem;
    display: inline-block;
    background-color: #ffffff; /* White background for contrast */
    padding: 0.7rem 1.5rem; /* Padding around the button text */
    border-radius: 8px; /* Rounded corners */
    color: #198754; /* Green text to match the background */
    text-decoration: none; /* Remove underline */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
}

    .button-2:hover {
        background-color: #e0e0e0; /* Light gray on hover */
    }

.bold-text-11 {
    font-weight: bold;
}



.privacy-link {
    color: #198754; /* White text for contrast */
    text-decoration: none; /* Remove underline */
    font-size:12px;
}

.privacy-link:hover {
    text-decoration: underline; /* Underline on hover for better user interaction */
}


@media (max-width: 768px) {
    .footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #198754; /* Dark green background for a distinct footer look */
        border-top: 1px solid #e0e0e0; /* Subtle border at the top of the footer */
        box-shadow: 0px -3px 15px rgba(0, 0, 0, 0.1); /* Subtle shadow at the top */
        position: relative;
        height: 60px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

