.pricing-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #188754 !important; /* Green background */
    border-radius: 8px;
    padding: 2rem;
    margin: 1rem;
    position: relative;
    box-shadow: 0px 20px 30px rgb(128, 128, 128); /* Enhanced shadow */
    color: #ffffff; /* Text color set to white for readability */
    height: 100% !important;
}

.pricing-icon {
    width: 150px;
    object-fit: cover;
    margin-bottom: 1rem;
    border-radius: 8px;
}

.heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 24px;
    color: #ffffff;
    width:100%
}

.button-primary-3 {
    padding: 0.8rem 2rem;
    background-color: #ffffff;
    color: #198754;
    text-decoration: none;
    border-radius: 8px;
    transition: background-color 0.3s;
    margin-top: 1rem;
}

    .button-primary-3:hover {
        background-color: #d4d4d4;
    }


.pricing-feature-list {
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 100%; /* Full width to utilize the card width */
}

.pricing-feature {
    list-style:none;
    margin: 6px 0; /* Provides vertical space between items */
}

    .pricing-feature:before {
        margin: 6px 0; /* Provides vertical space between items */
        content: '';
        height: 10px; /* Size of the bullet */
        width: 10px; /* Size of the bullet */
        background-color: #ffffff; /* Bullet color */
        border-radius: 50%; /* Makes the bullet circular */
        position: absolute; /* Positions the bullet absolutely within the `li` */
        left: 5%; /* Aligns the bullet to the very left of the `li` */
    }
/* Responsive styling for smaller devices */
@media (max-width: 768px) {
    .pricing-card {
        min-width: 300px;
        padding: 1rem;
    }
}
