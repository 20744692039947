/* Blog.css */


.no-underline {
    text-decoration: none;
    color: inherit;
}

    .no-underline:hover {
        text-decoration: none;
        color: inherit;
    }

.blog-content {
    text-align: justify;
}

.info-card{
    min-height:350px;

}

.info-icon{
    min-height:175px;
    min-width:300px;
    border-radius:5px;
}

.blog-title{
    margin-top:20px;
    font-size:22px;
    text-align:center;
    
}

.blog-header, .blog-link {
    color: #188754;
}

.blog-image {
    width: 100%;
    margin-bottom: 20px; /* Add space below the image */
}

/* Optional: Add responsiveness to your image */
@media (max-width: 768px) {
    .blog-image {
        width: 100%;
    }
}
